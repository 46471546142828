@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Teko:wght@300;400;500;600;700&display=swap");
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

::selection {
  @apply bg-my-purple-100 text-white;
}

@layer base {
  html {
    font-family: Poppins, system-ui, sans-serif;
    scroll-behavior: smooth;
  }
}

.technologies-icon {
  @apply w-5 grayscale hover:grayscale-0 transition-all ease-in-out duration-300;
}

.project-card:hover .project-image {
  @apply transform mt-[152px] -translate-y-[100%];
}

.project-card:hover .project-btns {
  @apply opacity-100 -right-9;
}

.project-btn:hover {
  @apply bg-opacity-0 border-2;
}

.tech-container:hover .tech-name {
  @apply text-gray-900;
}

a#scroll-btn {
  position: absolute;
  height: 50px;
  width: 30px;
  @apply border-2 border-gray-900 dark:border-white;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 1rem;
  border-radius: 3em;
}
a#scroll-btn:before {
  position: absolute;
  content: "";
  margin: auto;
  left: 0;
  right: 0;
  top: 10px;
  height: 8px;
  width: 8px;
  @apply bg-gray-900 dark:bg-white;
  border-radius: 50%;
  animation: move-down 2s infinite;
}
@keyframes move-down {
  80% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
  }
}
a#scroll-btn:after {
  position: absolute;
  content: "SCROLL DOWN";
  width: 72px;
  font-size: 10px;
  left: -20px;
  bottom: 3.5rem;
  @apply text-gray-900 dark:text-white;
  opacity: 0.5;
}
